import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		isConnect: false,
		callTronWeb: null,
		sendTronWeb: null,
		callPool: null,
		sendPool: null,
		callMineToken: null,
		sendMineToken: null,
		pledgeBalance: '--',
		mineBalance: '--',
	},
	mutations: {
		updateIsConnect(state, payload) {
			state.isConnect = payload;
		},
		updateCallPool(state, payload) {
			state.callPool = payload;
		},
		updateSendPool(state, payload) {
			state.sendPool = payload;
		},
		updateCallTronWeb(state, payload) {
			state.callTronWeb = payload;
		},
		updateSendTronWeb(state, payload) {
			state.sendTronWeb = payload;
		},
		updateCallMineToken(state, payload) {
			state.callMineToken = payload;
		},
		updateSendMineToken(state, payload) {
			state.sendMineToken = payload;
		},
		updatePledgeBalance(state,payload) {
			state.pledgeBalance = payload;
		},
		updateMineBalance(state,payload) {
			state.mineBalance = payload;
		}
	},
	actions: {

	},
	modules: {}
})
