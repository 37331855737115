import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { Col, Row, Overlay, Loading, Field, Toast, Collapse, CollapseItem, Icon } from 'vant';
import VueI18n from 'vue-i18n';
Vue.use(Col).use(Row).use(Overlay).use(Loading).use(Field).use(VueI18n).use(Toast).use(Collapse).use(CollapseItem).use(Icon);
import 'vant/lib/col/style';
import 'vant/lib/row/style';
import 'vant/lib/overlay/style';
import 'vant/lib/loading/style';
import 'vant/lib/field/style';
import 'vant/lib/toast/style';
import 'vant/lib/collapse/style';
import 'vant/lib/collapse-item/style';
import 'vant/lib/icon/style';
import zh from './i18n/zh.js'
import en from './i18n/en.js'

Vue.config.productionTip = false;

const i18n = new VueI18n({
	locale: localStorage.getItem('local') || 'zh',
	messages: {
		zh,
		en,
	}
})

new Vue({
	router,
	store,
	i18n,
	render: function(h) {
		return h(App)
	}
}).$mount('#app')
