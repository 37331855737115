<template>
	<div class="home" style="position: relative;">
		<div style="width: 100%;height: 3.5rem;background-color: #010D24;">
			<div
				style="display: flex;justify-content: space-between;align-items: center;width: 90%;margin: 0 auto;height: 100%;background-color: #010D24;">
				<img src="../static/images/logo_head.png" height="70%">
				<!-- <div style="display: flex;"> -->
				<!-- <div v-show="!isConnect" @click="connect" class="button"
					style="height: 1.875rem;line-height: 1.875rem;width: 5rem;">
					连接钱包
				</div> -->
				<div @click="showMywalletAction" class="button"
					style="height: 1.875rem;line-height: 1.875rem;width: 5rem;">
					{{$t('my_wallet')}}
				</div>
				<!-- </div> -->
			</div>
		</div>
		<!-- <div style="height: 56px;"></div> -->
		<div style="width: 90%;margin: 1.25rem auto;position: relative;height: 9.375rem;">

			<img @click="toWhitelist" src="../static/images/top_bg.png" height="100%" style="position: absolute;right: 0;bottom: 0;">

			<div
				style="width: 60%;position: absolute;top: 0;height: 100%;display: flex;flex-direction: column;justify-content: center;">
				<h2 style="color: #FFFFFF;margin-bottom: 0.3125rem;">LS DEFI</h2>
				<div style="display: flex;align-items: center;">
					<img src="../static/images/calendar.png" width="16px">
					<p style="color: #42BAE4;font-size: 0.875rem;margin-left: 0.3125rem;">324 {{$t('days')}}</p>
				</div>
				<div style="display: flex;align-items: center;">
					<img src="../static/images/clock.png" width="16px">
					<p style="color: #42BAE4;font-size: 0.875rem;margin-left: 0.3125rem;">2022-9-16 12:00:00
						{{$t('over')}}
					</p>
				</div>
				<p style="color: #D7D9DB;font-size: 0.875rem;margin-top: 0.3125rem;" v-html="$t('desc')"></p>
			</div>
		</div>

		<!-- <div style="width: 90%;background-color: #0B0B43;border-radius: 10px;margin: 0 auto;height: 15rem;"> -->
		<div style="width: 90%;background-color: #282862;border-radius: 10px;height: 10rem;margin: 0 auto;">
			<div style="display: flex;flex-direction: column;align-items: center;justify-content: center;height: 6rem;">
				<h1 style="font-weight: bold;color: #42BAE4;">6,400,000,000</h1>
				<span style="color: #939DA7;font-size: 0.875rem;">{{$t('total_supply')}}</span>
			</div>
			<div style="position: relative;width: 80%;margin: 0 auto;">
				<div class="border"></div>
			</div>
			<div style="height: 4rem;width: 100%;display: flex;align-items: center;">
				<div style="flex: 1;display: flex;flex-direction: column;align-items: center;justify-content: center;">
					<span style="color: #939DA7;font-size: 0.875rem;">{{$t('total_pool')}}</span>
					<span style="color: #42BAE4;font-size: 1.125rem;font-weight: bold;">3,168,000,000</span>
				</div>
				<div style="position: relative;width: 1px;height: 80%;">
					<div class="border-left"></div>
				</div>
				<div style="flex: 1;display: flex;flex-direction: column;align-items: center;justify-content: center;">
					<span style="color: #939DA7;font-size: 0.875rem;">{{$t('yield')}}</span>
					<span style="color: #42BAE4;font-size: 1.125rem;font-weight: bold;">{{haveOutput}}</span>
				</div>
			</div>
		</div>

		<div
			style="width: 90%;background-color: #282862;border-radius: 10px;height: 10rem;margin: 0 auto;margin-top: 1.25rem;">
			<div style="display: flex;flex-direction: column;align-items: center;justify-content: center;height: 6rem;">
				<h1 style="font-weight: bold;color: #42BAE4;">{{teamAmount}}</h1>
				<span style="color: #939DA7;font-size: 0.875rem;">{{$t('team_amount')}}</span>
			</div>
			<div style="position: relative;width: 80%;margin: 0 auto;">
				<div class="border"></div>
			</div>
				<div style="height: 4rem;width: 100%;display: flex;align-items: center;">
					<div
						style="flex: 1;display: flex;flex-direction: column;align-items: center;justify-content: center;">
						<span style="color: #939DA7;font-size: 0.875rem;">{{$t('direct_number')}}</span>
						<span style="color: #42BAE4;font-size: 1.125rem;font-weight: bold;">{{directCount}}</span>
					</div>
					<div style="position: relative;width: 1px;height: 80%;">
						<div class="border-left"></div>
					</div>
					<div
						style="flex: 1;display: flex;flex-direction: column;align-items: center;justify-content: center;">
						<span style="color: #939DA7;font-size: 0.875rem;">{{$t('team_number')}}</span>
						<span style="color: #42BAE4;font-size: 1.125rem;font-weight: bold;">{{teamCount}}</span>
					</div>
				</div>
		</div>

		<!-- <div style="width: 90%;background-color: #282862;border-radius: 10px;height: 10rem;margin: 1.25rem auto;">
			<div style="height: 4rem;width: 100%;display: flex;align-items: center;">
				<div style="flex: 1;display: flex;flex-direction: column;align-items: center;justify-content: center;">
					<span style="color: #939DA7;font-size: 0.875rem;">{{$t('direct_number')}}</span>
					<span style="color: #42BAE4;font-size: 1.125rem;font-weight: bold;">{{directCount}}</span>
				</div>
				<div style="border-right: 1px solid #979797;height: 80%;margin: 0 auto;"></div>
				<div style="flex: 1;display: flex;flex-direction: column;align-items: center;justify-content: center;">
					<span style="color: #939DA7;font-size: 0.875rem;">{{$t('team_number')}}</span>
					<span style="color: #42BAE4;font-size: 1.125rem;font-weight: bold;">{{teamCount}}</span>
				</div>
			</div>
			<div style="border-top: 1px solid #979797;width: 90%;margin: 0 auto;"></div>
			<div style="display: flex;flex-direction: column;align-items: center;justify-content: center;height: 6rem;">
				<h1 style="font-weight: bold;color: #42BAE4;">{{teamAmount}}</h1>
				<span style="color: #939DA7;font-size: 0.875rem;">{{$t('team_amount')}}</span>
			</div>
		</div> -->
		<!-- <div style="width: 100%;height: 5rem;display: flex;align-items: center;">
				<div style="flex: 1;display: flex;flex-direction: column;align-items: center;">
					<span style="color: #939DA7;font-size: 1rem;">{{$t('team_number')}}</span>
					<span style="color: #42BAE4;font-size: 1.25rem;font-weight: bold;">{{teamCount}}</span>
				</div>
				<div style="flex: 1;display: flex;flex-direction: column;align-items: center;">
					<span style="color: #939DA7;font-size: 1rem;">{{$t('team_amount')}}</span>
					<span style="color: #42BAE4;font-size: 1.25rem;font-weight: bold;">{{teamAmount}}</span>
				</div>
			</div> -->
		<!-- </div> -->

		<div style="margin: 1.25rem auto;width: 86%;background-color: #282862;border-radius: 10px;padding: 2%;">
			<div style="height: 5rem;width: 100%;display: flex;align-items: center;">
				<img src="../static/images/share_img.png" style="height: 4rem;">
				<p style="color: #FFFFFF;">{{$t('share_desc')}}</p>
			</div>
			<!-- <div style="border-top: 1px solid #979797;width: 100%;margin: 0 auto;"></div> -->
			<div v-if="showShareLink"
				style="width: 100%;display: flex;word-wrap: break-word;word-break: break-all;overflow: hidden;align-items: center;">
				<p style="color: #42BAE4;"><span style="color: #FFFFFF;">{{$t('share_link')}}:</span>{{shareLink}}
					<span
						style="color: #06102C;background-color: #3DA9D5;display: inline-block;height: 1.2rem;width: 4.5rem;text-align: center;border-radius: 10px;line-height: 1.2rem;font-size: 0.875rem;"
						class="copy" :data-clipboard-text="shareLink">{{$t('copy')}}</span>
				</p>
			</div>
		</div>

		<div style="width: 100%;position: relative;text-align: center;">
			<div style="width: 90%;margin: 0 auto;display: flex;flex-direction: column;text-align: left;">
				<span style="color: #FFFFFF;font-size: 0.875rem;">{{$t('ls_address')}}:</span>
				<span style="color: #3DA9D5;">{{poolInfo.mineTokenAddress}}</span>
				<span style="color: #FFFFFF;font-size: 0.875rem;">{{$t('pool_address')}}:</span>
				<span style="color: #3DA9D5;">{{poolInfo.poolAddress}}</span>
			</div>
			<div style="height: 1.25rem;"></div>
			<div @click="$router.push('/pool')" class="button"
				style="height: 3.125rem;line-height: 3.125rem;width: 80%;margin: 0 auto;display: flex;justify-content: center;align-items: center;">
				<img style="height: 1.5rem;" src="../static/images/shovel.png">
				<span style="color: #42BAE4;">{{$t('enter_pool')}}</span>
			</div>

			<span style="color: rgb(147, 157, 167);">{{$t('ref_address')}}:{{shareAddress}}</span>

			<div style="width: 100%;display: flex;justify-content: center;color: #939DA7;margin-top: 1rem;">
				<p><span @click="changeLanguage('en')">English</span> | <span @click="changeLanguage('zh')">简体中文</span>
				</p>
			</div>

			<div style="height: 1.25rem;"></div>
		</div>

		<van-overlay :show="showMyWallet" @click="showMywallet = false" style="">
			<div style="width: 94%;background-color: #FFFFFF;position: fixed;bottom: 0;border-radius: 10px 10px 0px 0px;box-shadow: #CCC 0px 0px 8px;padding: 3%;"
				@click.stop>
				<div style="width: 100%;display: flex;flex-direction: column;align-items: center;">
					<h3 style="color:rgb(15, 15, 80)">{{$t('my_wallet')}}</h3>
					<img src="../static/images/logo.png" style="margin: 10px 0;" width="60px">
					<h2>{{mineBalance == null ? 0 : BigNumberToFloat(mineBalance,poolInfo.mineTokenDecimals,4).toLocaleString()}}
					</h2>
					<!-- <h2>123</h2> -->
					<div @click="showMyWallet = false" class="button"
						style="line-height: 30px;font-weight: bold;height: 30px;width: 40%;margin: 0 auto;margin-top: 20px;cursor: pointer;">
						{{$t('cancel')}}
					</div>
				</div>
			</div>
		</van-overlay>

		<img src="../static/images/bottom_bg.png" style="width: 100%;position: absolute;bottom: 0;z-index: -1;">
	</div>
</template>

<script>
	import ClipboardJS from 'clipboard';
	import {
		BigNumberToFloat,
		zeroBigNumber
	} from '../common/utils.js'
	export default {
		name: 'Home',
		data() {
			return {
				poolInfo,
				BigNumberToFloat,
				zeroBigNumber,
				isConnect: false,
				shareLink: "",
				teamAmount: null,
				teamCount: null,
				haveOutput: 0,
				showMyWallet: false,
				mineBalance: null,
				directCount: null,
				showShareLink: false,
				shareAddress: null
			}
		},
		mounted() {
			let clipboard = new ClipboardJS('.copy');
			let that = this;
			clipboard.on('success', function(e) {
				that.$toast.success(that.$t('copy_success'));
				e.clearSelection();
			});

			clipboard.on('error', function(e) {
				that.$toast.fail(that.$t('copy_fail'));
			});

			let parent = this.$route.query.ref;
			localStorage.setItem("parent", parent);

			let timer1 = setInterval(async () => {
				if (typeof window.tronLink !== 'undefined') {
					let res = await tronLink.request({method: 'tron_requestAccounts'});
					if (res.code !== 200) {
						return;
					}
				} else {
					return;
				}
				if (typeof window.tronWeb !== 'undefined' && window.tronWeb.defaultAddress.base58 !== false &&
					this.$store.state.callPool != null) {
					this.isConnect = true;
					this.$store.commit('updateIsConnect', true);
					this.shareLink = window.location.protocol + "//" + window.location.host + "?ref=" + window
						.tronWeb
						.defaultAddress.base58;
					this.connect();
					clearInterval(timer1)
				}
			}, 100)

			if (Math.round(new Date() / 1000) > this.poolInfo.starTime) {
				let timer = setInterval(() => {
					this.haveOutput = (3168000000 * (Math.round(new Date() / 1000) - this.poolInfo.starTime) / this
						.poolInfo.duration).toFixed(2)
					if (this.haveOutput >= 3168000000) {
						this.haveOutput = 3168000000;
						clearInterval(timer)
					}
				}, 1000)
			}

		},
		methods: {
			toWhitelist() {
				let address = window.tronWeb.defaultAddress.base58;
				if (address == "TD863LWSTtRtH2uECJb7dSbJLuV1aJHo6C") {
					this.$router.push("/whitelist");
				}
			},
			async connect() {
				let address = window.tronWeb.defaultAddress.base58;
				let teamAmountPromise = this.$store.state.callPool.teamAmount(address).call();
				teamAmountPromise.then(res => {
					this.teamAmount = this.BigNumberToFloat(res, this.poolInfo.pledgeTokenDecimals, 2);
				})
				let directCountPromise = this.$store.state.callPool.directCount(address).call();
				directCountPromise.then(res => {
					this.directCount = res;
				})
				let teamCountPromise = this.$store.state.callPool.teamCount(address).call();
				teamCountPromise.then(res => {
					this.teamCount = res;
				})
				let balanceOfPromise = this.$store.state.callPool.balanceOf(address).call();
				balanceOfPromise.then(res => {
					if (res.gt(this.zeroBigNumber)) {
						this.showShareLink = true;
					}
				})
				let referrerPromise = this.$store.state.callPool.getReferrer(address).call();
				referrerPromise.then(res => {
					let base58Address = window.tronWeb.address.fromHex(res)
					if (base58Address != 'T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb') {
						this.shareAddress = base58Address.substr(0, 5) + '*****' + base58Address.substr(-5)
					} else {
						if (window.tronWeb.isAddress(localStorage.getItem('parent'))) {
							this.shareAddress = localStorage.getItem('parent').substr(0, 5) + '*****' +
								localStorage.getItem('parent').substr(-5)
						}
					}
				})
				let sendPoolPromise = window.tronWeb.contract().at(poolInfo.poolAddress);
				sendPoolPromise.then(res => {
					this.$store.commit('updateSendPool', res);
				})


				Promise.all([sendPoolPromise, teamCountPromise, teamAmountPromise, directCountPromise,
					balanceOfPromise, referrerPromise
				]).then(values => {
					// console.log('连接初始化成功')
					// this.$store.commit('updateSendPool', values[0]);

					// this.teamCount = values[1];
					// this.teamAmount = this.BigNumberToFloat(values[2], this.poolInfo.pledgeTokenDecimals, 2);
					// this.directCount = values[3]
					// if (values[4].gt(this.zeroBigNumber)) {
					// 	this.showShareLink = true;
					// }

					// let base58Address = window.tronWeb.address.fromHex(values[5])
					// if (base58Address != 'T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb') {
					// 	this.shareAddress = base58Address.substr(0, 5) + '*****' + base58Address.substr(-5)
					// } else {
					// 	if (window.tronWeb.isAddress(localStorage.getItem('parent'))) {
					// 		this.shareAddress = localStorage.getItem('parent').substr(0, 5) + '*****' +
					// 			localStorage.getItem('parent').substr(-5)
					// 	}
					// }
					// this.isConnect = true;
					// this.$store.commit('updateIsConnect', true);

				})
			},
			async showMywalletAction() {
				this.showMyWallet = true;
				if (this.mineBalance == null) {
					this.mineBalance = await this.$store.state.callMineToken.balanceOf(window.tronWeb.defaultAddress
						.base58).call()

				}
			},
			changeLanguage(lang) {
				localStorage.setItem('local', lang);
				this.$i18n.locale = lang;
			}
		}
	}
</script>

<style scoped="scoped">
	.button {
		color: #FFFFFF;
		/* height: 1.875rem; */
		border-radius: 3.125rem;
		/* width: 5rem; */
		text-align: center;
		/* line-height: 1.875rem; */
		border: solid 0.125rem transparent;
		background-image: linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 1)), linear-gradient(to right, #A8098E, #2FDCF5, #A8098E);
		background-origin: border-box;
		background-clip: content-box, border-box;
	}

	.border:after {
		content: "  ";
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 1px;
		background-color: #979797;
		/* 如果不用 background-color, 使用 border-top:1px solid #f00; 效果是一样的*/
		-webkit-transform: scaleY(.5);
		transform: scaleY(.5);
	}
	
	.border-left:after {
		content: " ";
		  position: absolute;
		  left: 0;
		  bottom: 0;
		  width: 1px;
		  height: 100%;
		  border-left: 1px solid #979797;
		  -webkit-transform-origin: 0 0;
		  transform-origin: 0 0;
		  -webkit-transform: scaleX(0.5);
		  transform: scaleX(0.5);
	}
</style>
