<template>
	<div id="app">
		<keep-alive>
			<router-view />
		</keep-alive>


		<!-- <div style="height: 3.125rem;"></div> -->

		<van-overlay style="z-index: 1000;" :show="showLoading">
			<div style="width: 100%;display: flex;align-items: center;height: 100%;">
				<div style="padding: 20px 0;width: 90%;background-color: #FFFFFF;margin: 0 auto;box-shadow: 10px 10px 10px #000000;border-radius: 0.625rem;display: flex;align-items: center;flex-direction: column;"
					@click.stop>
					<van-loading color="#272727" size="80px" style="margin: 20px 0;" />
					<p style="font-size: 1.125rem;">{{ $t('loading') }}</p>
				</div>
			</div>
		</van-overlay>
	</div>
</template>

<script>
import TronWeb from 'tronweb'
export default {
	name: 'App',
	data() {
		return {
			showLoading: true,
			poolInfo: poolInfo
		}
	},
	async mounted() {

		this.isConnect = this.$store.state.isConnect;
		let tronWeb = new TronWeb({
			fullHost: this.poolInfo.nodeUrl,
			// headers: { "TRON-PRO-API-KEY": 'd0ca3dfb-5123-4f1d-bf45-22f949388042' },
		})

		tronWeb.setAddress('THdq35fvQA4xKLqff7QbY7Ubx8dpVrg7gz');
		let poolPromise = tronWeb.contract().at(poolInfo.poolAddress);
		let minePromise = tronWeb.contract().at(poolInfo.mineTokenAddress);

		Promise.all([poolPromise, minePromise]).then(res => {
			// console.log('CallPool初始化成功')
			this.$store.commit('updateCallPool', res[0]);
			// console.log('MineToken初始化成功')
			this.$store.commit('updateCallMineToken', res[1]);
			this.showLoading = false;
		})
		this.$store.commit('updateCallTronWeb', tronWeb);
	},
	methods: {
		changeLanguage(lang) {
			localStorage.setItem('local', lang);
			this.$i18n.local = lang;
		}
	}
}
</script>

<style>
* {
	margin: 0;
	padding: 0;
}

html,
body {
	height: 100%;
}

#app {
	min-height: 100%;
	background-color: rgb(1, 13, 36);
	position: relative;
	z-index: 1;
}</style>
