const zh = {
	my_wallet: '我的钱包',
	days: '天挖矿周期',
	over: '结束',
	desc: 'LS生态治理通证<br/>质押STAR+BK的LP挖矿产出',
	total_supply: '总供应量',
	total_pool: '矿池总量',
	yield: '已产出量',
	direct_number: '直推人数',
	team_number: '团队人数',
	team_amount: '团队质押',
	share_desc: '我们为您提供10%的LS推荐奖励。复制下面的推荐链接立即分享并获得奖励。',
	share_link: '邀请链接',
	copy: '点击复制',
	ls_address: 'LS合约地址',
	pool_address: '矿池合约地址',
	enter_pool: '进入矿池',
	cancel: '取消',
	loading: '正在加载...',
	got: '已挖',
	get: '提取',
	get_reward: '提取奖励',
	pledge_dig_compound_mode: '质押挖矿组合方式',
	pledge: '质押',
	pledged: '已质押',
	approve: '授权',
	approved: '已授权',
	redeem: '解押',
	available_redeem: '可解押',
	available_pledge: '可质押',
	please_input: '请输入',
	need: '需要',
	dig_not_over: '挖矿还未结束',
	not_sufficient_funds: '余额不足',
	share_link_error: '请使用正确的邀请链接',
	withdraw_star_balance_overflow: '最大解押:',
	copy_success: '复制成功',
	copy_fail: '复制失败',
	not_start: '挖矿还未开始',
	int_number_error: '只能质押整数',
	ref_address: '推荐人地址',
	mapping: '映射',
	input_bsc: '请输入BSC地址',
	address_error: '地址错误',
	ref_not_mapping: '上级未映射',
	repeat_settings: '重复设置',
	amount_is_zero: '数量为0',
	data_reading: '数据读取中'
}
export default zh;