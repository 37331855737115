import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Whitelist from '../views/Whitelist.vue'

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/pool',
    name: 'Pool',
	meta: {
		connect: true,
	},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/Pool.vue')
    }
  },{
    path: '/whitelist',
    name: 'Whitelist',
    component: Whitelist
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
	if(to.meta.connect) {
		if(router.app.$options.store.state.isConnect) {
			document.body.scrollTop = 0;
			document.documentElement.scrollTop = 0;
			window.pageYOffset = 0
			next();
		} else {
			// alert(router.app.$options.i18n.messages[router.app.$options.i18n.locale].please_connect_wallet)
			next('?ref='+localStorage.getItem('parent'));
		}
	} else {
		next();
	}
})

export default router
