const en = {
	my_wallet: 'My Wallet',
	days: 'days',
	over: 'over',
	desc: 'LS is the living matter in the interstellar game of blockchain pledge STAR+BK LP dig LS.',
	total_supply: 'Total supply',
	total_pool: 'Total pool',
	yield: 'Yield',
	direct_number: 'Direct number',
	team_number: 'Team number',
	team_amount: 'Team amount',
	share_desc: 'We offer you a 10% LS referral bonus. Copy the link below to share now and receive rewards.',
	share_link: 'Share link',
	copy: 'Copy',
	ls_address: 'LS address',
	pool_address: 'Pool address',
	enter_pool: 'Enter pool',
	cancel: 'Cancel',
	loading: 'Loading...',
	got: 'Got',
	get: 'Get',
	get_reward: 'Get reward',
	pledge_dig_compound_mode: 'Compound mode',
	pledge: 'Pledge',
	pledged: 'Pledged',
	approve: 'Approve',
	approved: 'Approved',
	redeem: 'Redeem',
	available_redeem: 'Available redeem',
	available_pledge: 'Available pledge',
	please_input: 'Please input',
	need: 'Need',
	dig_not_over: 'The mining is not over yet',
	not_sufficient_funds: 'Not sufficient funds',
	share_link_error: 'Please use the correct share link',
	withdraw_star_balance_overflow: 'Max redeem:',
	copy_success: 'Copy success',
	copy_fail: 'Copy fail',
	not_start: 'The mining hasn\'t started yet',
	int_number_error: 'Only integers can be pledged',
	ref_address: 'Referrer link',
	mapping: 'Mapping',
	input_bsc: 'Please input BSC address',
	address_error: 'Address error',
	ref_not_mapping: 'Ref not mapping',
	repeat_settings: 'Repeat settings',
	amount_is_zero: 'Amount is zero',
	data_reading: 'Data reading'
}

export default en;