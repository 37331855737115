<template>
    <div style="display: flex;align-items: center;flex-direction: column;">
        <input v-model="user" style="width: 80%;max-width: 350px;" type="text" placeholder="请输入地址">
        <button @click="addWhitelist">提交</button>
    </div>
</template>
<script>
export default {
  name: "Whitelist",
  data() {
    return {
        user: ''
    }
  },
  methods: {
    async addWhitelist() {
        let res = await fetch(`http://127.0.0.1:3000/addWhitelist/${this.user}`,{
            method: "POST"
        });
        res = await res.json();
        this.user = '';
        alert("添加成功")
    }
  }
}
</script>