import {
	ethers
} from 'ethers';

export function decodeParams(types, output, ignoreMethodHash) {
	const AbiCoder = ethers.utils.AbiCoder;
	const ADDRESS_PREFIX_REGEX = /^(41)/;
	const ADDRESS_PREFIX = "41";

	if (!output || typeof output === 'boolean') {
		ignoreMethodHash = output;
		output = types;
	}

	if (ignoreMethodHash && output.replace(/^0x/, '').length % 64 === 8)
		output = '0x' + output.replace(/^0x/, '').substring(8);

	const abiCoder = new AbiCoder();

	if (output.replace(/^0x/, '').length % 64)
		throw new Error('The encoded string is not valid. Its length must be a multiple of 64.');
	return abiCoder.decode(types, output).reduce((obj, arg, index) => {
		if (types[index] == 'address')
			arg = ADDRESS_PREFIX + arg.substr(2).toLowerCase();
		obj.push(arg);
		return obj;
	}, []);
}

export function BigNumberToFloat(soure, decimals, keep) {
	if(!soure._isBigNumber) {
		soure = ethers.BigNumber.from(soure)
	}
	return soure.div(ethers.BigNumber.from(10).pow(decimals - keep).toString()).toNumber() / Math.pow(10, keep)
}

export function FloatToBigNumber(soure, decimals) {
	let nums = soure.toString().split('.');
	if (nums.length === 1) {
		return ethers.BigNumber.from(10).pow(decimals).mul(nums[0])
	} else {
		let soureDecimals = nums[1].length > decimals ? decimals : nums[1].length;
		return ethers.BigNumber.from(nums[0].concat(nums[1].substr(0, decimals))).mul(ethers.BigNumber.from(10).pow(
			decimals - soureDecimals))
	}
}

export let zeroBigNumber = ethers.BigNumber.from(0)